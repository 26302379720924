import { Link } from "gatsby"
import React, { useState } from "react"
import logo from "../images/logo-header-alt.svg"
import clsx from "clsx"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="text-white bg-indigo-500 flex flex-wrap items-center px-4 h-32 lg:h-24 lg:px-16 ">
      <div className="flex items-center justify-center w-full h-full">
        <Link to="/">
          <img src={logo} className="h-16 mr-8 lg:mr-4" />
        </Link>
        <div className="flex flex-col items-start lg:items-center lg:flex-row lg:justify-between lg:h-full lg:w-full">
          <Link to="/" className="no-underline text-white">
            <h1 className="text-2xl xl:text-3xl font-sans-alt font-bold">
              {siteTitle}
            </h1>
          </Link>
          <div className="text-lg h-full md:flex -ml-6">
            <Url to="/story">Our Story</Url>
            <Url to="/contact">Contact</Url>
            <Url to="/volunteer">Volunteer</Url>
            <Url to="/support">Support Us</Url>
          </div>
        </div>
      </div>

      <button
        className="block md:hidden border border-white flex items-center px-3 py-2 rounded "
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <svg
          className="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>

      {/* <div
        className={clsx(
          "md:flex md:items-center w-full md:w-auto md:h-full",
          isExpanded ? `block` : `hidden`
        )}
      >
        
      </div> */}
    </nav>
  )
}

const Url = ({ className, children, ...props }) => {
  return (
    <Link
      {...props}
      className={clsx(
        "flex items-center mt-4 md:pt-1 md:mt-0 no-underline ml-6 h-full border-b-8 border-transparent hover:border-indigo-200",
        className
      )}
    >
      {children}
    </Link>
  )
}

export default Header
