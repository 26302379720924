import React from "react"

const Footer = () => {
  return (
    <footer className="bg-gray-800">
      <div className="flex justify-between mx-auto py-8 px-32 text-sm">
        <p className="text-white">
          Created by{" "}
          <a
            href="https://whiting.io"
            className="font-bold no-underline text-white"
          >
            Caleb Whiting
          </a>
        </p>

        <p>
          <a
            href="https://github.com/taylorbryant/gatsby-starter-tailwind"
            className="font-bold no-underline text-white"
          >
            Source
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
