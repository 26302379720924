import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "typeface-patua-one"
import "typeface-gudea"
import "typeface-overpass"

import Header from "./header"
import Footer from "./footer"

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="flex flex-col min-h-screen text-grey-darkest font-sans">
          <Header siteTitle={data.site.siteMetadata.title} />

          <div className="flex flex-col flex-1 w-full">{children}</div>

          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
